/* global jQuery */
import anime from 'animejs/lib/anime.es.js';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

// MAIN NAVIGATION
/*const navLinks = document.querySelectorAll(
	"#mainNavigation .nav-item .nav-link"
);
const navLinksResults = Splitting({ target: navLinks, by: "chars" });*/

/*navLinks.forEach(function (item) {
	item.addEventListener("mouseenter", function hoverAnimation() {
		anime({
			targets: item.querySelectorAll(".char"),
			translateY: [25, 0],
			duration: 500,
			easing: "easeInOutQuad",
			delay: anime.stagger(50),
		});
	});
	item.addEventListener("mouseleave", function hoverAnimation() {
		anime({
			targets: item.querySelectorAll(".char"),
			translateY: [25, 0],
			duration: 500,
			easing: "easeInOutQuad",
			delay: anime.stagger(50),
		});
	});
});*/

/*function splitText(selector, spanClass) {

    var textWrappers = typeof selector === 'string' ? document.querySelectorAll(selector) : [selector];

    textWrappers.forEach(function(textWrapper) {
        const textArray = Array.from(textWrapper.textContent);
        const totalLetters = textArray.length;

        textWrapper.innerHTML = textArray.map(function(char, index) {
            if (char === ' ') {
                // Return a single space character for normal spaces
                return ' ';
            } else if (char === '\t') {
                // Return a non-breaking space character for tabs
                return '&nbsp;';
            } else {
                const reverseIndex = totalLetters - index - 1;
                return `<span class='${spanClass}' style='display:inline-block'>${char}</span>`;
            }
        }).join('');
    });
}*/

// Use the function to wrap text
// splitText('.title .title-text', 'letter');


// TITLE ANIMATION STARTS HERE

/*function titleMarquee() {
	let sectionBadges = document.querySelectorAll(".section-badge");

	// TITLE CLONE
	function titleClone(times) {
		sectionBadges.forEach((sectionBadge) => {
			let titleClone = sectionBadge.querySelector(".section-badge-text");
			if (titleClone) {
				for (let i = 0; i < times; i++) {
					let clone = titleClone.cloneNode(true);
					sectionBadge.appendChild(clone);
				}
			}
		});
	}

	// Initialize clones
	titleClone(4);

	// Start the animation
	anime({
		targets: sectionBadges,
		translateX: ["100%", "-100%"], // Move from right to left across the screen
		duration: 10000, // Duration of the animation (10 seconds)
		easing: "linear", // Continuous movement without easing
		loop: true, // Loop the animation forever
	});
}*/

// titleMarquee();

/*jQuery(document).ready(function ($) {
	jQuery(".statistics-digit").counterUp({
		delay: 10,
		time: 1000,
	});
});*/


// MEGA MENU

/*const menuHeading = document.querySelectorAll("#megaMenu-tabs li a");
const menuHeadingResults = Splitting({ target: menuHeading, by: "chars" });

menuHeading.forEach(function (item) {
	// splitText(item, "menu-chars");
	item.addEventListener("mouseenter", function hoverAnimation() {
		anime({
			targets: item.querySelectorAll(".char"),
			translateY: [100, 0],
			duration: 500,
			easing: "easeInOutQuad",
			delay: anime.stagger(15),
		});
	});
	item.addEventListener("mouseleave", function hoverAnimation() {
		anime({
			targets: item.querySelectorAll(".char"),
			translateY: [100, 0],
			duration: 500,
			easing: "easeInOutQuad",
			delay: anime.stagger(15),
		});
	});
});*/

const tabHeading = document.querySelectorAll(".portfolio-tabs li a");
const tabHeadingResults = Splitting({ target: tabHeading, by: "chars" });

tabHeading.forEach(function (item) {
// splitText(item, "char");
	item.addEventListener("mouseenter", function hoverAnimation() {
		anime({
			targets: item.querySelectorAll(".char"),
			opacity: [0, 1],
			duration: 50,
			easing: "easeOutCirc",
			delay: anime.stagger(50),

			translateY: [
				{
					value: function () {
						return anime.random(-15, 15);
					},
					duration: 200,
				},
				{
					value: 0,
					duration: 200,
				},
			],
		});
	});
});

anime({
	targets: ".service-logo .path",
	strokeDashoffset: [anime.setDashoffset, 0],
	stroke: "#FFF",
	easing: "easeInOutQuad",
	duration: 5000,
	delay: function (el, i) {
		return i * 10000;
	},
	loop: true,
});

let hirePromoImg = document.querySelectorAll(".hire-promo-img path");

anime({
	targets: "#user-right",
	translateX: [-15, 0],
	//opacity: [0, 1],
	duration: 1500,
	easing: "easeInOutQuad",
	direction: "alternate",
	loop: true,
	elasticity: 600,
	delay: function (el, index) {
		return index * 80;
	},
});
anime({
	targets: "#dev-left",
	translateX: [15, 0],
	//opacity: [0, 1],
	duration: 1500,
	easing: "easeInOutQuad",
	direction: "alternate",
	loop: true,
	delay: function (el, index) {
		return index * 80;
	},
});
anime({
	targets: "#cog-01",

	transformOrigin: "25% 25%",
	rotate: {
		value: "+=1turn", // 0 + 2 = '2turn'
		duration: 1800,
		easing: "easeInOutSine",
	},
	//opacity: [0, 1],
	// elasticity: 600,
	// duration: 1500,
	// easing: "easeInOutQuad",
	// direction: "alternate",
	loop: true,
});
// anime({
// 	targets: hirePromoImg, // Pass the actual element reference
// 	opacity: [0, 1],
// 	duration: 500,
// 	translateX: [0, 0],
// 	scale: [1.5, 1],
// 	delay: anime.stagger(50),
// 	transformOrigin: "100% 100%",
// });


anime.timeline({loop: true})
  .add({
    targets: '.ml10 .letter',
    rotateY: [-90, 0],
    duration: 1300,
    delay: (el, i) => 45 * i
  }).add({
    targets: '.ml10',
    opacity: 0,
    duration: 1000,
    easing: "easeOutExpo",
    delay: 1000
  });