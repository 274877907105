import { useEffect } from "react";
import anime from 'animejs/lib/anime.es.js';

const useTitleMarquee = (times = 6) => {
  useEffect(() => {
    const sectionBadges = document.querySelectorAll(".section-badge");

    // TITLE CLONE
    const titleClone = (times) => {
      sectionBadges.forEach((sectionBadge) => {
        let titleClone = sectionBadge.querySelector(".section-badge-text");
        if (titleClone) {
          for (let i = 0; i < times; i++) {
            let clone = titleClone.cloneNode(true);
            sectionBadge.appendChild(clone);
          }
        }
      });
    };

    titleClone(times);

      anime({
        targets: sectionBadges,
        translateX: ["100%", "-100%"], // Move from right to left across the screen
        duration: 10000, // Duration of the animation (10 seconds)
        easing: "linear", // Continuous movement without easing
        loop: true, // Loop the animation forever
      });
  }, [times]);
};

export default useTitleMarquee;
