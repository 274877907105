import axios from "./axios";
import {useSelector} from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showMessage = (msg, type) => {
    if (type === "success") {
        toast.success(msg, {
            position: "top-right",
        });
    } else if (type === "error") {
        toast.error(msg, {
            position: "top-right",
        });
    } else {
        toast.info(msg, {
            position: "top-right",
        });
    }
};

export const callAPI = (method, endpoint, data) => {
    let errors;

    let tkn = sessionStorage.getItem("access_token")
        ? sessionStorage.getItem("access_token") : '';

    if (tkn) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + tkn;
    }
    // axios.defaults.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (method === "post") {
        return axios
            .post(endpoint, data)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.message !== "") {
                        showMessage(`${res.data.message}`, "success");
                    }
                    return res.data.data;
                } else {
                    errors = res.data.errors;
                    Object.keys(errors).map(function (key, index) {
                        showMessage(`${errors[key]}`, "error");
                    });
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    errors = err.response.data.errors;
                    Object.keys(errors).map(function (key, index) {
                        showMessage(`${errors[key]}`, "error");
                    });
                } else if (err.response.status === 401) {
                    showMessage("Access denied.", "error");
                    window.location.href = "/";
                } else if (err.response.status === 404) {
                    showMessage("API request not found", "error");
                } else {
                    showMessage(err.message, "error");
                    //window.location.href = '/';
                }
            });
    }
    if (method === "get") {
        return axios
            .get(endpoint)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.message !== "") {
                        showMessage(`${res.data.message}`, "success");
                    }
                    if (res.data.hasOwnProperty("pagination")) {
                        let res_data = {
                            data: res.data.data,
                            pagination: res.data.pagination,
                        };
                        return res_data;
                    } else {
                        return res.data.data;
                    }
                } else {
                    errors = res.data.errors;
                    Object.keys(errors).map(function (key, index) {
                        showMessage(`${errors[key]}`, "error");
                    });
                }
                //
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    errors = err.response.data.errors;
                    if (Object.keys(err.response.data.data).length !== 0) {
                        Object.keys(errors).map(function (key, index) {
                            showMessage(`${errors[key]}`, "error");
                        });
                    }
                } else if (err.response.status === 401) {
                    showMessage("Access denied.", "error");
                    //window.location.href = "/";
                } else if (err.response.status === 404) {
                    showMessage("API request not found", "error");
                } else {
                    showMessage(err.message, "error");
                    //window.location.href = "/";
                }
            });
    }
    if (method === "delete") {
        return axios
            .delete(endpoint, data)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.message !== "") {
                        showMessage(`${res.data.message}`, "success");
                    }
                    return res.data.data;
                } else {
                    errors = res.data.errors;
                    Object.keys(errors).map(function (key, index) {
                        showMessage(`${errors[key]}`, "error");
                    });
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    errors = err.response.data.errors;
                    Object.keys(errors).map(function (key, index) {
                        showMessage(`${errors[key]}`, "error");
                    });
                } else if (err.response.status === 401) {
                    showMessage("Access denied.", "error");
                    window.location.href = "/";
                } else if (err.response.status === 404) {
                    showMessage("API request not found", "error");
                } else {
                    showMessage(err.message, "error");
                    //window.location.href = '/';
                }
            });
    }
};

export const useSiteSettings = () => {
    return useSelector((state) => state.siteSettings.siteSettings);
}