// LoaderOverlay.js
import React from "react";
import '../assets/scss/loader.scss';
import { useLoader } from "./Loader";
import { InfinitySpin, Oval } from "react-loader-spinner";

function LoaderOverlay() {
    const { loading } = useLoader();

    return loading ? (
        <div className="loader-overlay">
            <div className="loader">
                {/*<InfinitySpin width="200" color="#ec3237" />*/}
                <Oval
                    visible={loading}
                    height="60"
                    width="60"
                    color="#fff"
                    secondaryColor={"#246bfd"}
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </div>
    ) : null;
}

export default LoaderOverlay;