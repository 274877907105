import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as utils from '../Utils/common';

export const fetchSiteSettings = createAsyncThunk(
    'settings/fetchSiteSettings',
    async (_, thunkAPI) => {
        try {
            return await utils.callAPI("get", "settings");
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

const initialState = {
    siteSettings: null,
    isLoading: false,
    isError: false,
}

const siteSettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(fetchSiteSettings.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        })

        builder.addCase(fetchSiteSettings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.siteSettings = action.payload
        })

        builder.addCase(fetchSiteSettings.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
    })
});

export default siteSettingsSlice.reducer;