// import "./App.css";
// import "./App.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "swiper/scss";
// import './assets/scss/swiper.scss';
import "./assets/styles/line-awesome.min.css";
import "./assets/styles/animate.css";
import "./App.scss";
import WOW from "wowjs";
import React, {Suspense, useEffect} from "react";
// import "./assets/js/theme";
import "./assets/js/animation";
import useTitleMarquee from "./Hooks/useTitleMarquee";
import useImageReveal from "./Hooks/useImageReveal";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchSiteSettings} from "./Reducers/SiteSettingsSlice";
import LoaderOverlay from "./Utils/LoaderOverlay";
import {HelmetProvider, Helmet} from "react-helmet-async";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useButtonAnim from "./Hooks/useButtonAnim";
import useDataAnimateAttribute from "./Hooks/useDataAnimateAttribute";
const SiteRouters = React.lazy(() => import("./Routers/SiteRouters"));


function App() {
  useButtonAnim();
  useDataAnimateAttribute();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSiteSettings());
  });

  // useTitleMarquee();
  // useImageReveal();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<div
            style={{position: "fixed", background: '#000', width: "100%", height: "100%"}}>Loading...</div>}>
          <HelmetProvider>
            <Helmet titleTemplate={"%s | WebMobi Technologies"}>
              <title>Home</title>
              <meta name={"description"} content={"A leading Web App and Mobile App Development Company in Canada and India. Our Experienced developers have delivered the best solutions."} />
            </Helmet>
            <LoaderOverlay/>
            <Routes>
              <Route path={"/*"} element={<SiteRouters />} />
            </Routes>
          </HelmetProvider>
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
