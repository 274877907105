import { useEffect } from "react";
import anime from 'animejs/lib/anime.es.js';
import Splitting from "splitting";

const useButtonAnim = () => {
  useEffect(() => {
    let animBtns = document.querySelectorAll(".btn");
    const animBtnsResults = Splitting({ target: animBtns, by: "chars" });

    animBtns.forEach(function (item) {
      item.addEventListener("mouseenter", function hoverAnimation() {
        anime({
          targets: item.querySelectorAll(".char"),
          rotateX: 360,

          duration: 800,
          easing: "easeInOutQuad",
          delay: anime.stagger(50),
        });
        anime({
          targets: item.querySelectorAll("path"),
          translateX: [-25, 0],
          opecity: [0, 1],
          easing: "easeInOutQuad",
          duration: 1000,
        });
      });
      item.addEventListener("mouseleave", function hoverAnimation() {
        anime({
          targets: item.querySelectorAll(".char"),
          //opecity: [0, 1],
          rotateX: 0,
          duration: 800,
          easing: "easeInOutQuad",
          delay: anime.stagger(50),
        });
        anime({
          targets: item.querySelectorAll("path"),
          translateX: [-25, 0],
          opecity: [0, 1],
          easing: "easeInOutQuad",
          duration: 1000,
        });
      });
    });
  }, []);
};

export default useButtonAnim;
